<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="tabToggle">
      <el-tab-pane
        v-for="(item, index) in navList"
        :key="index"
        :label="item.key"
        :name="item.val"
      />
    </el-tabs>
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: '',
      navList: [
        {
          key: '商品类目管理',
          val: '/goods/category/manage'
        },
        {
          key: '商品类目维护',
          val: '/goods/category/set'
        },
        {
          key: '商品类目列表',
          val: '/goods/category/list'
        }
      ]
    }
  },
  mounted() {
    this.activeName = this.$route.path
  },
  methods: {
    // tab切换
    tabToggle() {
      this.$router.push(this.activeName)
    }
  }
}
</script>

<style lang="less" scoped></style>
